import styled from 'styled-components';

export const LetterSpace = styled.span`
	//letter-spacing: -22px;
`; 

export const TextBox = styled.div`
	// background-color: red;
	width: 100%;
`;

export const LabelIcon = styled.img`
	// margin-left: -50px;
`;
